import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './CreateAccount.scss';

class CreateAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: ''
        }
    }

    render() {
        return <div className="create-account">
            <a href={'/manage/' + this.props.token} target="_blank"
               className="btn btn--link btn--create-account">Manage My
                Booking</a>
        </div>
    }
}

CreateAccount.propTypes = {
    customer: PropTypes.object.isRequired,
    email: PropTypes.string.isRequired
}

export default CreateAccount;