import React from 'react'
import PropTypes from 'prop-types'
import Header from './Header'
import { BookingData } from '../Data/BookingData'

export default function BookingHeader (props) {
    let booking = props.booking
    let title = 'Enquire Now'
    if (booking.can_auto_confirm) {
        title = 'Book Now'
    }

    if (booking.booking_id) {
        title = 'Time to Party!'
    }
    let show_back = booking.step > 1
    if (booking.booking_id) {
        show_back = false
    }
    let prev_step = booking.step - 1
    if (booking.step === 99) {
        prev_step = booking.can_auto_confirm ? 2 : 1
    }
    if (booking.step === 99 && booking.party_type === 99) {
        prev_step = 1
    }
    let show_help = booking.can_fallback
    if (booking.step >= 3) {
        show_help = false
    }
    return <Header title={title} show_close={BookingData.canClose()} onClose={() => BookingData.closeBooking()}
                   show_help={false}
                   onHelp={() => BookingData.update({ step: 99 })}
                   show_back={show_back} onBack={() => BookingData.update({ 'step': prev_step })}/>
}

BookingHeader.propTypes = {
    booking: PropTypes.object.isRequired,
}