import React, {Fragment} from 'react';
import PreBookingInfo from "../Elements/PreBookingInfo";
import {BookingData} from "../Data/BookingData";
import TypeSelect from "../Elements/TypeSelect";
import BookingComplete from "../Elements/BookingComplete/BookingComplete";
import PreOrder from "../Elements/PreOrder/PreOrder";
import TimeSelect from "../Elements/TimeSelect/TimeSelect";
import UserData from "../Elements/UserData";
import moment from 'moment';
import DepositBar from "../Elements/Deposit/DepositBar";
import TermsText from "../Elements/TermsText";
import Button from "../../Form/Element/Button";
import BookingStep from "../../Utility/BookingStep/BookingStep";
import Notice from "../../Utility/Notice/Notice";
import ShortSummary from "../Elements/ShortSummary/ShortSummary";

export default function ChristmasBooking(props) {
    let booking = props.booking;
    if (booking.is_completed === true) {
        return <BookingComplete booking={props.booking} show_end_time={booking.step < 99}/>
    }
    let open_to_date = moment().toDate();
    if (props.booking.date) {
        open_to_date = moment(props.booking.date).toDate();
    }
    let minDate = moment();
    let openToDate = null;
    let month = minDate.format('MM');
    if (['11', '12', '01', '02'].indexOf(month) === -1) {
        minDate = minDate.month(10).startOf('month');
        openToDate = minDate.toDate();
    }
    if (booking.step === 1) {
        let can_proceed = props.booking.can_take_booking;
        ['no_people'].map((field) => {
            if (BookingData.getFieldError(field)) {
                can_proceed = false;
            }
            return field;
        });
        return <BookingStep step={1} name="pre-booking-info" type="christmas" hot_jar_url="enquiry-info" booking={props.booking}>
            <TypeSelect booking={props.booking} updating={props.updating} nextButton={<Button text="Next" onClick={() => BookingData.update({'step': 2})} disabled={!can_proceed} name="continue"/>}/>
        </BookingStep>
    }
    if (booking.step === 2) {
        let message = 'Oh dear! Looks like we are fully booked on this day. Do you want to try another day?';
        if (props.booking.date && ((booking.alternative_dates.length === 0 && booking.time_slots.length === 0) || booking.can_cater_for === false)) {
            return <Fragment>
                <BookingStep step={2} name="time-select" type="christmas" hot_jar_url="enquiry-availability-none"  booking={props.booking}>
                    <Notice name="no-brunch">
                        We can't auto confirm this booking at the moment, would you like to submit an enquiry instead?
                    </Notice>
                    <Button text="Yes" name="do-callback" onClick={() => {
                        BookingData.update({step: 99});
                    }}/>
                </BookingStep>
            </Fragment>
        }
        let types = BookingData.getTypeList().filter((type) => {
            let found = type.value.indexOf('Christmas') > -1 && type.key != props.booking.party_type;
            if (type.key == '97' && props.booking.no_people != 6) {
                found = false;
            }
            return found;
        });
        return <Fragment>
            <BookingStep step={2} name="time-select" type="christmas" hot_jar_url="enquiry-availability-check"  booking={props.booking}>
                <PreBookingInfo booking={props.booking} updating={props.updating}
                                filterDate={(date) => {
                                    let allowed_months = [10, 11, 0, 1];
                                    return allowed_months.indexOf(date.getMonth()) > -1;
                                }} minDate={minDate} openToDate={openToDate}
                />
                {props.booking.date && <TimeSelect booking={props.booking} updating={props.updating} show_price={true} stacked={true}
                            show_alternative={true} allow_fallback={false}
                            unavailable_message={message}/>}
            </BookingStep>
        </Fragment>
    }

    if (booking.step === 3) {
        let skip_text = 'Skip';
        if (props.booking.value > 0) {
            skip_text = 'Continue';
        }
        return <Fragment>
            <BookingStep step={3} name="pre-order" type="christmas" hot_jar_url="enquiry-pre-orders"  booking={props.booking}>
                <PreOrder booking={props.booking}
                          updating={props.updating}
                          intro_text="Sweet, we've added your christmas dining, would you like to change anything?"
                          can_skip={true}
                          skip_text={skip_text}/>
            </BookingStep>
            <DepositBar booking={props.booking} updating={props.updating} next_step={props.next_step}
                        skip_text={'Continue'} can_skip={props.booking.can_last_step}/>
        </Fragment>
    }
    if (booking.step === 4) {
        let skip_text = 'Save Booking';
        if (props.booking.deposit === 0) {
            skip_text = 'Complete Booking';
        }
        return <Fragment>
            <BookingStep step={4} name="user-data" type="christmas" hot_jar_url="enquiry-personal-details"  booking={props.booking}>
                <h2>Almost Done</h2>
                <ShortSummary booking={props.booking} show_end_time={true}/>
                <UserData booking={props.booking}/>
                <TermsText booking={props.booking}/>
            </BookingStep>
            <DepositBar booking={props.booking} updating={props.updating} next_step={props.next_step}
                        skip_text={skip_text} can_skip={props.booking.can_complete}
                        continueFunction={() => BookingData.completeBooking()}/>
        </Fragment>
    }
    if (booking.step === 99) {
        return <Fragment>
            <BookingStep step={99} name="fallback" type="christmas" hot_jar_url="enquiry-fallback-to-callback"  booking={props.booking}>
                <UserData booking={props.booking}
                          include_pre_booking={<PreBookingInfo booking={props.booking} updating={props.updating}
                                                               minDate={moment(props.booking.min_booking_date).toDate()}
                                                               openToDate={open_to_date} include_booking_time={true}
                          />}
                          additional_info={<p>Cool, so just fill in your details below and one of our team will get in touch with you as soon as they can via phone or email to talk business (joking, to talk party!)</p>}/>
                <TermsText booking={props.booking}/>
            </BookingStep>
            <DepositBar booking={props.booking} updating={props.updating} next_step={props.next_step}
                        skip_text="Send Enquiry" can_skip={props.booking.can_complete}
                        continueFunction={() => BookingData.completeBooking()}/>
        </Fragment>
    }
    return <BookingStep step={999} name="no-step" type="christmas" hot_jar_url="enquiry-error"  booking={props.booking}>
        <Notice name="no-step" variant="error">
            Woops something has gone wrong here..
        </Notice>
    </BookingStep>
}