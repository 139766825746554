import React, {Fragment} from 'react';
import PreBookingInfo from "../Elements/PreBookingInfo";
import {BookingData} from "../Data/BookingData";
import TypeSelect from "../Elements/TypeSelect";
import BookingComplete from "../Elements/BookingComplete/BookingComplete";
import TimeSelect from "../Elements/TimeSelect/TimeSelect";
import PreOrder from "../Elements/PreOrder/PreOrder";
import UserData from "../Elements/UserData";
import CompleteBooking from "../Elements/CompleteBooking";
import Button from "../../Form/Element/Button";
import BookingStep from "../../Utility/BookingStep/BookingStep";
import Notice from "../../Utility/Notice/Notice";
import DepositBar from "../Elements/Deposit/DepositBar";
import TermsText from "../Elements/TermsText";
import {Settings} from "../../Settings/Settings";
import moment from "moment";
import ShortSummary from "../Elements/ShortSummary/ShortSummary";

export default function BoothBooking(props) {
    let booking = props.booking;
    if (booking.is_completed === true) {
        return <BookingComplete booking={props.booking} show_end_time={booking.step < 99}/>
    }
    let open_to_date = moment().toDate();
    if (props.booking.date) {
        open_to_date = moment(props.booking.date).toDate();
    } else if (props.booking.min_booking_date && moment(props.booking.min_booking_date)) {
        open_to_date = moment(props.booking.min_booking_date).toDate();
    }
    if (booking.step === 1) {
        let can_proceed = props.booking.can_take_booking;
        ['no_people'].map((field) => {
            if (BookingData.getFieldError(field)) {
                can_proceed = false;
            }
            return field;
        });
        return <BookingStep step={1} type="drinks" name="pre-booking-info" hot_jar_url="enquiry-info" booking={props.booking}>
            <TypeSelect booking={props.booking} updating={props.updating} nextButton={<Button text="Next" onClick={() => BookingData.update({'step': 2})} disabled={!can_proceed} name="continue"/>}/>
        </BookingStep>
    }
    if (booking.step === 2) {
        return <BookingStep step={2} type="drinks" name="time-select" hot_jar_url="enquiry-availability-check" booking={props.booking}>
            <PreBookingInfo booking={props.booking} updating={props.updating}
                            minDate={moment(props.booking.min_booking_date).toDate()}
                            openToDate={open_to_date}/>
            {props.booking.date !== null && <TimeSelect booking={props.booking} updating={props.updating} show_price={true} stacked={true}/>}
        </BookingStep>
    }

    if (booking.step === 3) {
        let sub_text = '';
        let skip_text = 'Continue';
        if (booking.min_spend !== null && booking.min_spend > 0) {
            if (props.booking.last_step_reason === 'Min Spend Not Reached') {
                skip_text = 'Only ' + Settings.currencyFormatterRounded.format(booking.min_spend - booking.value) + ' to go!';
            }
            sub_text = <Fragment>
                You’ve come to the right place to plan your BIG NIGHT OUT!
                <br/>
                You need to spend a minimum
                of <strong>{Settings.currencyFormatter.format(booking.min_spend)}</strong> to secure this
                booking. Pick from a selection of our favourite packages below or create your own on the custom tab.
            </Fragment>
            // sub_text = <Fragment>But because this time is really popular we'll need you to spend {Settings.currencyFormatterRounded.format(booking.min_spend)} to secure the table. Just pick from the options below and we'll have these delivered straight to your table on arrival!</Fragment>
        }
        return <Fragment>
            <BookingStep step={3} type="drinks" name="pre-order" hot_jar_url="enquiry-pre-orders"  booking={props.booking}>
                <PreOrder booking={props.booking}
                          updating={props.updating}
                          intro_text="We've got a table available– lucky you!"
                          sub_text={sub_text} can_skip={true}/>
            </BookingStep>
            <DepositBar booking={props.booking} updating={props.updating} next_step={props.next_step}
                        complete_text={skip_text} can_skip={props.booking.can_go_to_last_step} continue={true}
                        continueFunction={() => {
                            BookingData.update({step: 4});
                        }}/>
        </Fragment>
    }
    if (booking.step === 4) {
        return <BookingStep step={4} type="drinks" name="user-data" hot_jar_url="enquiry-personal-details"  booking={props.booking}>
            <ShortSummary booking={props.booking} show_end_time={true}/>
            <UserData booking={props.booking}/>
            <TermsText booking={props.booking}/>
            <CompleteBooking booking={props.booking}/>
        </BookingStep>
    }

    if (booking.step === 99) {
        return <Fragment>
            <BookingStep step={99} name="fallback" type="at_table_masterclass"
                         hot_jar_url="enquiry-fallback-to-callback"  booking={props.booking}>
                <UserData booking={props.booking}
                          additional_info={<p>Cool, so just fill in your details below and one of our team will get in
                              touch with you as soon as they can via phone or email to talk business (joking, to talk
                              party!)</p>}/>
                <TermsText booking={props.booking}/>
            </BookingStep>
            <DepositBar wet={true} booking={props.booking} updating={props.updating} next_step={props.next_step}
                        skip_text="Send Enquiry" can_skip={props.booking.can_complete}
                        continueFunction={() => BookingData.completeBooking()}/>
        </Fragment>
    }

    return <BookingStep step={999} name="no-step" type="drinks" hot_jar_url="enquiry-error"  booking={props.booking}>
        <Notice name="no-step" variant="error">
            Woops something has gone wrong here..
        </Notice>
    </BookingStep>
}